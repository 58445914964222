$primary-color: #f2f5f7;
$secondary-color: #1E2430;
$main-heading-font-size: 35px;
$heading-font-size: 24px;
$sub-heading-font-size: 20px;
$secondry-para-font-size: 16px;
$box-heading-font-size: 16px;
$primary-font-weigth: 600;
$secondary-border-radius: 20px;
$primary-font-family: 'Ubuntu', sans-serif;
$secondary-font-family: 'Kanit', sans-serif;
$box-shadow: 0px 5px 10px 0px #0000001A;
$main-padding : 50px 0;
$padding-bottom: 50px;
$padding-bottom: 50px;

.image-section {
    text-align: center;
    .carousel-item {
        margin-bottom: 30px;
    }

    h3 {
        margin-bottom: 20px;
    }

    .carousel-indicators {
        bottom: -2%;
        position: absolute;
        right: 0;
        left: 0;
        z-index: 2;
        display: flex;
        justify-content: start;
        padding: 0;
        margin-right: 0;
        margin-bottom: 25px;
        margin-left: 0;
        background: #c1c1c1;
        height: 4px;
        align-items: center;
        border-radius: 20px;
        margin: auto auto 25px;
        width: 70%;

        @media (max-width:767px) {
            bottom: -1%;
        }

        @media (max-width:384px) {
            bottom: -1.5%;
        }
    }


    .carousel-inner {
        // overflow: visible;
    }

    .carousel-control-next,
    .carousel-control-prev {
        display: none;
    }

    .carousel-indicators [data-bs-target] {
        margin: 0;
        // border-radius: 20px;
        width: 100%;
        background-color: #1E2430;
        opacity: 0;
    }

    .carousel-indicators .active {
        opacity: 1 !important;
    }

    margin-bottom: 40px;

    .images-items {
        position: relative;
        margin-top: 10px;
        transition: .4s;
        overflow: hidden;
        border-radius: 20px;

        &:hover .image-overlay {
            opacity: 1;
            transform: translate(0, 0%);
        }

        &:hover {
            transform: scale(1.02);
        }

        img {
            border-radius: 20px;
            // margin-bottom: 15px;
            transition: 0.5s;

            @media (min-width:1500px) {
                // height: auto;
            }

            @media (max-width:992px) and (orientation:landscape) {
                object-fit: cover;
            }

            @media (max-width:576px) {
                height: auto;
            }
        }

        .image-title {
            position: absolute;
            top: 0;
            padding: 5px;
            background-color: #f2f5f7;
            min-width: 250px;
            border-top-left-radius: 20px;
            border-bottom-right-radius: 20px;
            text-align: center;
            border: 1px solid #0000002b;
            display: flex;
            justify-content: center;

            @media (max-width:992px) and (orientation:landscape) {
                flex-wrap: wrap;
                min-width: auto;
                justify-content: start;

                h5 {
                    text-align: start;
                }
            }

            h5 {
                font-weight: 800;

                @media (min-width:1900px) {
                    font-size: 24px;
                }
            }

            small {
                font-size: 12px;
                margin-left: 5px;
                font-weight: 500;

                @media (min-width:1900px) {
                    font-size: 18px;
                }
            }

            P {
                color: #1E2430;
            }
        }

        .image-overlay {
            position: absolute;
            top: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            width: 100%;
            background: rgb(0 0 0 / 35%);
            opacity: 0;
            transition: .4s;
            transform: translate(0, -100%);
            border-radius: 20px;

            a {
                // padding: 5px 20px;
                margin: 5px;
                border-radius: 20px;
                font-family: $secondary-font-family;
                // font-size: 12px;
                text-decoration: none;
                color: #1E2430;

                // &:hover {
                //     background-color: #0069ff;
                //     color: #f2f5f7;
                // }
            }

            button {
                padding: 5px 20px;
                margin: 5px;
                border-radius: 20px;
                font-family: $secondary-font-family;
                font-size: 12px;
                color: #1E2430;

                // @media (max-width:1366px) {
                //     font-size: 10px;
                // }

                &:hover {
                    background-color: #08428d;
                    color: #f2f5f7;
                }

                &:hover a {
                    color: #f2f5f7;
                }
            }
        }
    }
}

.project-demo-modal {
    .modal-dialog {
        max-width: 85%;
        height: 100vh;
        /* margin-right: auto; */
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .modal-title.h4 {
        display: none;
    }

    .modal-header {
        position: absolute;
        right: 14px;
        top: 14px;
        z-index: 2;
        border: none;

        button.btn-close {
            background-color: #eb5f1f;
            border-radius: 50%;
        }
    }

    .modal-content {
        background-color: #0000004d;
        backdrop-filter: blur(5px);
    }
}