$primary-color: #F2F5F7;
$secondary-color: #1E2430;
$main-heading-font-size: 35px;
$heading-font-size: 24px;
$sub-heading-font-size: 20px;
$secondry-para-font-size: 16px;
$box-heading-font-size: 16px;
$primary-font-weigth: 600;
$secondary-border-radius: 20px;
$primary-font-family: 'Ubuntu', sans-serif;
$secondary-font-family: 'Kanit', sans-serif;
$box-shadow: 0px 5px 10px 0px #0000001A;
$main-padding : 50px 0;
$padding-bottom: 50px;

.contact-us-section {
    .carousel-indicators {
        display: none;
    }

    p {
        color: #1E2430;
    }



    .contact-form-section {
        .card {
            margin-top: 30px;
            padding: 30px;
            border-radius: 20px;
            box-shadow: 0px 10px 24px 0px #ECEDF4;
            border-color: snow;

            p {
                text-align: justify;
            }
        }
    }

    .our-office {
        h3 {
            margin-bottom: 30px;
        }

        padding-top: 30px;

        .our-office-content.card {
            min-height: 250px;
            margin-bottom: 30px;
            padding: 10px 0;
            height: 100%;
            box-shadow: 0px 10px 24px 0px #ECEDF4;
            border-color: snow;
            padding: 0 20px;

            @media (max-width:1024px) {
                .row:nth-child(1) {
                    justify-content: space-between;
                }
            }

            @media (max-width:576px) {
                height: auto;
                line-height: 1.5;
            }

            .country-image {
                height: 150px !important;

                @media (max-width:576px) {
                    height: 100px !important;
                }
            }
        }
    }

    .create-appointment {
        h3:hover {
            color: #08428d;
        }
    }
}

@media (max-width:992px) and (orientation:landscape) {
    // .conatact-page {
    //     height: 0vh;
    // }
}

.contact-form-box {

    .modal-dialog {
        height: 100%;
        /* vertical-align: middle; */
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    button {
        background-color: #08428d;
        border-color: transparent;
        margin: auto;

        &:hover {
            background-color: transparent;
            color: #08428d;
            border-color: #08428d;
        }
    }
}