@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

* {
  border: 0;
  margin: 0;
  box-sizing: border-box;
  user-select: none;
  -webkit-user-select: none;
}

div#root {
  /* background: #fff; */
}

body {
  background-color: #f2f5f7;
  top: 0 !important;
}

.VIpgJd-ZVi9od-aZ2wEe-wOHMyf-ti6hGc,
.VIpgJd-yAWNEb-L7lbkb {
  display: none !important;
}

.service-list font.VIpgJd-yAWNEb-VIpgJd-fmcmS-sn54Q {

  @media (max-width: 575px) {
    background-color: transparent !important;
    box-shadow: none !important;
  }
}

.hide-btn {
  @media (max-width: 575px) {
    display: none;
  }
}

.detail-img {
  display: flex;
  flex-direction: column;
  margin: 40px auto 0;

  @media (max-width:992px) {
    margin-top: 60px;
  }
}

.service-list {
  display: flex;
  justify-content: center;

  @media (max-width: 992px) {
    margin-top: 15px;
    margin-bottom: 0;
  }

  @media (max-width: 575px) {
    margin-top: 35px;
  }

  li {
    margin: 5px 10px;
    font-weight: 500;
    cursor: pointer;
    border-bottom: 1px solid transparent;
    font-size: 16px !important;

    @media (max-width:575px) {
      font-size: 11px !important;
      font-weight: 700;
      margin: 5px 8px;

    }

    &:hover {
      color: #08428D;
      border-color: #08428D;
    }

    a {
      text-decoration: none;
      color: #1E2430;
      font-size: 16px !important;

      @media (max-width:576px) {
        font-size: 12px !important;
      }

      &:hover {
        color: #08428D;
      }
    }
  }
}

.conatact-page .carousel-indicators {
  display: none;
}

.conatact-page {
  overflow: hidden;
  margin-bottom: 50px;

  label {
    color: #F2F5F7;
  }


  .form-control {
    background-color: #f2f5f7 !important;
    opacity: 0.8;
    border-color: transparent;
    color: #1E2430;
    /* text-transform: capitalize; */
    font-size: 12px;

    @media (min-width:1900px) {
      font-size: 18px;
    }

    @media (max-width:992px) and (orientation:landscape) {
      font-size: 11px;
      height: 30px;
      text-transform: capitalize;
    }
  }

  ::-webkit-input-placeholder {
    color: #1E2430;
    text-transform: capitalize;
    font-weight: 500;
  }

  button:hover {
    color: #f2f5f7;
    background-color: transparent;
    background: transparent !important;
    border-color: #f2f5f7 !important;
  }
}

.skiptranslate.goog-te-gadget span {
  display: none;
}

.cls-1 {
  /* transform: rotate(360deg); */
  transform-origin: 50% 45%;
  transition: .5s;
}

.cls-1,
.cls-2,
.cls-3 {
  fill: #08428D;
}

.cls-2 {
  /* transform: rotate(360deg); */
  transform-origin: 50% 45%;
  transition: 0.7s;
}

.cls-3 {
  /* transform: rotate(360deg); */
  transform-origin: 50% 45%;
  transition: 1.4s;
}

#Layer_1:hover .cls-1,
#Layer_1:hover .cls-2,
#Layer_1:hover .cls-3 {
  transform: translate(0%);
}

.bim-section a.carousel-control-prev {
  top: -40px !important;
  left: unset !important;
  right: 70px !important;
}

.bim-section a.carousel-control-next {
  top: -40px !important;
  left: unset !important;
  right: 0px !important;

  @media (max-width:576px) {
    top: -40px !important;
  }
}

.bim-section video {
  border-radius: 20px;
}

.green-section a.carousel-control-prev {
  top: -36px !important;
  left: unset !important;
  right: 70px !important;
}

.green-section a.carousel-control-next {
  top: -36px !important;
  left: unset !important;
  right: 0px !important;
}



@media (max-width: 768px) {
  .aos-init.aos-animate[data-aos="fade-up"] {
    animation: none !important;
    transition: none !important;
  }

  .aos-init.aos-animate[data-aos="fade-right"] {
    animation: none !important;
    transition: none !important;
  }

  .aos-init.aos-animate[data-aos="fade-left"] {
    animation: none !important;
    transition: none !important;
  }

  .aos-init.aos-animate[data-aos="fade-down"] {
    animation: none !important;
    transition: none !important;
  }
}