$primary-color: #F2F5F7;
$secondary-color: #1E2430;
$main-heading-font-size: 35px;
$heading-font-size: 24px;
$sub-heading-font-size: 20px;
$secondry-para-font-size: 16px;
$box-heading-font-size: 16px;
$primary-font-weigth: 600;
$secondary-border-radius: 20px;
$primary-font-family: 'Ubuntu', sans-serif;
$secondary-font-family: 'Kanit', sans-serif;
$box-shadow: 0px 5px 10px 0px #0000001A;
$main-padding : 40px 0;

.banner-caraousel {
    .carousel-indicators [data-bs-target] {
        border-bottom: 0;
        border-top: 0;
        height: 7px;
        width: 35px;
        border-radius: 20px;
        background-color: #F2F5F7;
        background: #F2F5F7;
        opacity: 1;

        &.active {
            background-color: #08428d;
        }
    }

    height: 100vh;
    background-color: #1E2430;

    @media (max-width:767px) {
        height: 35vh;
    }

    .carousel.slide {
        height: 100%;

        a.carousel-control-next,
        a.carousel-control-prev {
            display: none;
        }
    }

    .carousel-inner {
        height: 100%;
    }

    .carousel-item,
    .carousel-caption {
        height: 100%;
        width: 100%;
        padding: 0;
        bottom: 0;

        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            object-position: center;
        }
    }

    .carousel-caption {
        right: 0;
        left: 0;
    }
}