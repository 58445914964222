$primary-color: #f2f5f7;
$secondary-color: #1E2430;
$main-heading-font-size: 35px;
$heading-font-size: 24px;
$sub-heading-font-size: 20px;
$secondry-para-font-size: 16px;
$box-heading-font-size: 16px;
$primary-font-weigth: 600;
$secondary-border-radius: 20px;
$primary-font-family: 'Ubuntu', sans-serif;
$secondary-font-family: 'Kanit', sans-serif;
$box-shadow: 0px 5px 10px 0px #0000001A;
$main-padding : 50px 0;


.architecture-visual {
    p {
        color: #1E2430;
    }

    img {
        border-radius: 20px;
        width: 100%;
    }
}