$primary-color: #F2F5F7;
$secondary-color: #1E2430;
$main-heading-font-size: 35px;
$heading-font-size: 24px;
$sub-heading-font-size: 20px;
$secondry-para-font-size: 15px;
$box-heading-font-size: 15px;
$primary-font-weigth: 600;
$secondary-border-radius: 20px;
$primary-font-family: 'Ubuntu', sans-serif;
$secondary-font-family: 'Kanit', sans-serif;
$box-shadow: 0px 5px 10px 0px #0000001A;
$main-padding : 40px 0;

@mixin flex-property() {
    display: flex;
    justify-content: center;
    align-items: center;
}

@mixin button-class () {
    color: #1E2430;
    background-color: #1E2430;
    font-size: 20px;
    border-radius: 10px;
    font-weight: 500;
}

@mixin navbar-menu () {
    color: #333;
    font-size: 14px;
    font-weight: 500;
}

@mixin height-property () {
    height: 100%;
    width: 100%;
}

@mixin cross-button() {
    color: #1E2430;
    width: 50px;
    height: 50px;
}

@mixin arrow-button() {
    color: #1E2430;
    width: 50px;
    height: 50px;
}

h3 {
    font-family: $primary-font-family;
    font-size: 24px;
    color: $secondary-color;
    font-weight: 700;

    @media (max-width:992px) and (orientation: landscape) {
        font-size: 22px !important;
    }

    @media (max-width:576px) {
        font-size: 20px !important;
    }
}

h4 {
    font-size: $sub-heading-font-size;
    font-family: $primary-font-family;
    color: $secondary-color;

}

h5 {
    font-size: $box-heading-font-size;
    font-family: $primary-font-family;
    color: $secondary-color;
    margin-bottom: 0;
}

p {
    font-family: $secondary-font-family;
    font-size: $secondry-para-font-size;
    color: $secondary-color;
    margin-bottom: 0;
    color: #F2F5F7;

    @media (min-width:1800px) {
        font-size: 24px;
    }
}

@media (min-width:1900px) {
    small {
        font-size: 18px;
    }

    a {
        font-size: 24px;
    }
}

.main-page-heading {
    margin-bottom: 30px;

    @media (max-width: 575px) {
        margin-bottom: 30px;
    }
}

h3 span {
    // background: linear-gradient(360deg, rgba(148, 140, 138, 0.25) -17.24%, rgba(255, 255, 255, 0.025) 72.41%);
    // border-left: 4px solid #939598;
    padding: 8px;
    position: relative;
}

h3 span::after {
    content: "";
    position: absolute;
    background: url(../public/assets/images/border.png);
    background-repeat: no-repeat;
    bottom: -5px;
    left: 0;
    right: 0;
    width: 100%;
    height: 6px;
    background-size: contain;
}

.deveoper-page .container {
    padding-bottom: 40px;
}

.home-page {
    background-color: #f2f5f7;
}

.cubedots-logo {
    position: fixed;
    top: 15px;
    z-index: 1031;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    // @media (max-width: 575px){
    //     // height: 60px;
    //     top: 10;
    //     background-color: #ffffff05;
    //     backdrop-filter: blur(2px);
    // }

    img {
        filter: drop-shadow(0 0 6px rgba(0, 0, 0, 0.3));
    }

    @media (max-width:576px) {
        img {
            width: 40px !important;
        }
    }
}

.burger-box {
    background-color: #00000030;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    border-radius: 10px;
}

@media (min-width:1200px) {
    .container {
        max-width: 95%;
    }
}

.quick-contact {
    position: fixed;
    right: 3.5%;
    cursor: pointer;
    transition: .5s;
    bottom: 10%;
    z-index: 3;
    height: 50px;

    @media (max-width:992px) and (orientation:landscape) {
        padding: 0 5px;
        position: fixed;
        top: 70%;
    }

    @media (max-width:576px) {
        position: fixed;
        top: 40%;
    }

    &:hover {
        transform: scale(1.1);
    }

    &:hover h6 {
        color: #08428D;
    }

    a {
        text-decoration: none;
    }

    h6 {
        font-weight: 600;
        margin-bottom: 0;
        color: #F2F5F7;
        font-size: 18px;
        color: #1E2430;
    }
}


.social-btns {
    /* height: 90px; */
    /* margin: auto; */
    /* font-size: 0; */
    /* text-align: center; */
    position: fixed;
    top: 0;
    /* bottom: 0; */
    left: 0;
    bottom: 0;
    z-index: 3;
    display: flex;
    flex-direction: column;
    left: 0px;
    justify-content: center;

    .social-icons {
        display: flex;
        flex-direction: column;
        background: #0000005c;
        padding: 15px 7px;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
    }

    @media (max-width:992px) and (orientation:landscape) {
        left: 5px;
    }

    .btn {
        display: inline-block;
        background-color: #F2F5F7;
        width: 32px;
        height: 32px;
        line-height: 1.9;
        margin: 5px 0;
        text-align: center;
        position: relative;
        overflow: hidden;
        border-radius: 28%;
        box-shadow: 0 5px 15px -5px rgba(0, 0, 0, 0.3);
        opacity: 0.99;
        padding: 0;
        border: transparent;

        &:before {
            content: '';
            width: 120%;
            height: 120%;
            position: absolute;
            transform: rotate(45deg);
        }
    }
}



.social-btns .btn .fa {
    font-size: 24px;
    vertical-align: middle;
}

.social-btns .btn .fa {
    transform: scale(0.8);
}

.social-btns .btn:focus:before,
.social-btns .btn:hover:before {
    top: -10%;
    left: -10%;
}

.social-btns .btn,
.social-btns .btn:before,
.social-btns .btn .fa {
    transition: all 0.35s;
    transition-timing-function: cubic-bezier(0.31, -0.105, 0.43, 1.59);
}

.social-btns .btn.facebook:before {
    background-color: #3b5998;
}

.social-btns .btn.facebook .fa {
    color: #3b5998;
}

.social-btns .btn.whatsapp:before {
    background-color: #00e676;
}

.social-btns .btn.whatsapp .fa {
    color: #00e676;
}

.social-btns .btn:before {
    top: 90%;
    left: -110%;
}

.social-btns .btn.youtube:before {
    background-color: #ff0000;
}

.social-btns .btn.youtube .fa {
    color: #ff0000;
}

.social-btns .btn.instagram:before {
    background-color: #fe0161;
}

.social-btns .btn.instagram .fa {
    color: #fe0161;
}

.social-btns .btn.whatsapp .fa {
    color: #00e676;
}

.social-btns .btn.linkedin:before {
    background-color: #0077b5;
}

.social-btns .btn.linkedin .fa {
    color: #0077b5;
}

.social-btns .btn:focus .fa,
.social-btns .btn:hover .fa {
    color: #F2F5F7;
    transform: scale(0.9);
}

.conatact-page form {
    width: 60%;
    margin-left: 50px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;

    @media (max-width:992px) and (orientation:landscape) {
        width: 87%;
    }

    @media (max-width:767px) {
        width: 85%;
        margin-bottom: 50px;
    }

    select {
        background-color: #f2f5f7;
        opacity: 0.8;
        border-color: transparent;
        color: #1E2430;
        text-transform: capitalize;
        padding: .375rem 3px;
        color: #1E2430;
        font-size: 12px;
        appearance: auto;
        background-image: none;
        font-weight: 500;

        @media (max-width:992px) and (orientation:landscape) {
            font-size: 11px;
            height: 30px;
        }

        @media (max-width:576px) {
            height: 30px;
        }
    }

    @media (max-width:576px) {
        .form-control {
            height: 30px;
        }
    }
}

.coming-soon {
    background: #1E2430;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    h1 {
        color: #F2F5F7;
    }
}

@media (max-width:992px) and (orientation:landscape) {
    .aos-init {
        // display: block;
    }
}

.pre-design-content {
    display: flex;
    flex-direction: column;
}

.pre-design-content strong.main-page-heading span {
    color: #1E2430;
    font-size: 20px;
    font-family: $primary-font-family;

    @media (max-width: 992px) and (orientation: landscape) {
        font-size: 24px;
    }

    @media (max-width: 575px) {
        font-size: 22px;
    }
}

.service-section.home-page .video-section .video-content {
    justify-content: space-between;
    background-color: #0000005c;
    border-radius: 10px;
    align-items: center;


    @media (max-width: 992px) and (orientation: landscape) {
        background-color: #0000005c;
        border-radius: 20px;
        align-items: center;
        height: 15%;
        width: auto;
        padding: 0 20px;
        text-align: center;
    }


    @media (max-width: 575px) {
        background-color: #0000005c;
        border-radius: 15px;
        align-items: center;
        height: 20%;
        width: 94%;
        padding: 0 20px;
    }
}

.submit-btn-contact {
    width: 50%;

    @media (max-width:576px) {
        width: 45%;
    }
}

@media (max-width:575px) {
    .render-page.tabsHeading .pre-design-content strong.main-page-heading span {
        font-size: 16px;
    }

    .renderImages.tabsHeading .renderImages360.render-page.pre-design-content span {
        font-size: 16px;
    }

    .pre-design-content h3 {
        font-size: 16px !important;
    }

    .first-carousel.green-carousel .pre-design-content h3 {
        margin-top: 20px;
    }
}

.service-section.home-page .video-section::before {
    display: none;
}

.service-section.home-page .video-section .video-content li {
    // text-align: center;
}

.service-section.home-page .video-section .video-content li p {
    color: #f2f5f7;
    // padding: 0 -5px;
}

.service-section.home-page .video-section .video-content li.active {
    font-weight: 700;
}

.service-section.home-page .video-section .progress-box {
    display: none;
}

.fade.render-modal-section.modal.show .modal-dialog {
    max-width: 100%;
    margin: 0;
    height: 100%;
    position: relative;
}

.fade.render-modal-section.modal.show .modal-content {
    height: 100%;
    border-radius: 20px;
}

.fade.video-modal.render-modal-section .pnlm-container {
    width: 100% !important;
    height: 100% !important;
    border-radius: 20px;
}

.pnlm-controls-container {
    top: 20px !important;
}

.fade.render-modal-section.modal.show .modal-body {
    padding: 0;
}

.fade.render-modal-section.modal.show {
    padding: 17px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.render-tabs-items {
    img {
        // margin-bottom: 20px;
        border-radius: 10px;
    }
}

.render-page {
    img {
        box-shadow: none !important;
    }
}

.render-page-tabs button.back-btn {
    background-color: #00000015;
    // color: #f2f5f7;
    padding: 0px 2px;
    // margin-top: 26px;
    border-radius: 5px;
    border: 1px solid transparent;

    @media (max-width: 575px) {
        // margin-top: 18px;
    }

    &:hover {
        background-color: transparent;
        color: #08428d;
        border-color: #08428d;
    }
}

.render-closeBtn {
    position: absolute;
    top: 10px;
    right: 0;
    z-index: 99;
    background: transparent;

    svg {
        fill: #08428D;
        width: 30px;
        height: 30px;
    }
}

.render-closeBtn {
    position: absolute;
    right: 0;
    z-index: 99;
}

.move-direction {
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    color: #f2f5f7;
    font-size: 77px;
    background: #00000024;
    user-select: none;
    -webkit-user-select: none;
    top: 0;

    small {
        animation-name: example;
        animation-duration: 4s;
        animation-iteration-count: infinite;
        position: relative;
    }
}

@keyframes example {
    0% {
        left: 0px;
        top: 0px;
    }

    25% {
        left: 10px;
        top: 0px;
    }

    50% {
        left: 20px;
        top: 20px;
    }

    75% {
        left: 0px;
        top: 20px;
    }

    100% {
        left: 0px;
        top: 0px;
    }
}

.preview-images-tabs {
    position: absolute;
    top: 0;
    // background: #0000006b;
    width: 100%;
    height: 100%;
    /* left: 0; */
    /* right: 0; */
    /* margin: 1.5rem; */
    bottom: 0;
    border-radius: 10px;
    // opacity: 0;
    padding: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.5s;

    small {
        color: #f2f5f7;
    }
}

.preview-images-tabs:hover {
    background: #0000006b;
    opacity: 1;
}

/*New Service Page*/
.service-section.home-page .video-section {
    height: 100vh;

    @media (max-width:1200px) {
        height: auto;
    }

    @media (max-width:992px) and (orientation:landscape) {
        height: 100vh;
    }

    .video-content {
        margin-bottom: 0;
        bottom: 0;
        background-color: #578BC8CC;
        border-radius: 10px 10px 0 0;
        height: 40px;
        padding: 0 10px;

        @media (max-width:1200px) {
            // bottom: 5px;
        }

        li p {
            position: relative;
            line-height: 0.8;
            padding-right: 15px;

            &::after {
                position: absolute;
                content: "";
                width: 2px;
                height: 100%;
                background-color: #FFFFFF99;
                right: 0;

                @media (max-width:576px) {
                    position: unset;
                }
            }

            @media (max-width:576px) {
                line-height: 1.2;
            }
        }

        li:last-child p {
            &::after {
                display: none;
            }
        }

        li.active {
            font-weight: normal;
        }
    }
}

.Interior-renders.row {
    position: relative;
    justify-content: end !important;
}

.service-content-new {
    position: absolute;
    left: 0;
    z-index: 1;
    background-color: #F1F7FFCC;
    padding: 20px 15px;
    min-height: 75%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (max-width:767px) {
        position: relative;
        top: -50px;
        width: 90%;
        margin: auto;
        height: auto;
    }

    @media (max-width:576px) {
        padding-top: 10px;
    }
}

.service-content-new1 {
    position: absolute;
    right: 0;
    z-index: 1;
    background-color: #F1F7FFCC;
    padding: 20px 15px;
    min-height: 75%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (max-width:767px) {
        position: relative;
        top: -50px;
        width: 90%;
        margin: auto;
        height: auto;
    }

    @media (max-width:576px) {
        padding-top: 10px;
    }
}

.preview-images img {
    width: 40px !important;
    height: auto !important;
    margin: 0px 5px;
}

.first-slide-service.row {
    justify-content: end !important;
    align-items: center;
    position: relative;
    overflow: hidden;
}

.second-slide-service {
    align-items: center;
    position: relative;
    overflow: hidden;
}

.service-tabs-menu {
    border: none;
    justify-content: center;
    padding: 30px 0 !important;

    li {
        background-color: #ECF1F6;
        padding: 5px;
    }

    @media (max-width:576px) {
        padding: 20px 0 0 !important;
        margin-bottom: 10px;
        flex-wrap: nowrap;
        overflow: overlay;
        justify-content: space-between;
        margin-bottom: 20px;
    }
}

.service-tabs-menu.nav.nav-tabs button {
    background: transparent;
    color: #70757B;
    white-space: nowrap;
    border: none;

    @media (max-width:576px) {
        font-size: 14px;
    }
}

.service-tabs-menu.nav.nav-tabs button.nav-link.active {
    background-color: #F1F7FF;
    color: #578BC8;
    font-weight: 600;
}

.service-section a img,
.preview-images img {
    transition: 0.4s;
}

.service-section a img:hover {
    transform: scale(1.1);
}

.preview-images img:hover {
    transform: scale(1.1);
}

.detail-img.container .nav-link {
    background-color: transparent;
    color: #70757B;
}

.detail-img.container .nav-link.active {
    background-color: #F1F7FF !important;
    color: #578BC8 !important;
    font-weight: 500;
}

.tabsHeading {
    position: relative;
}

.tabsHeading h6:first-child {
    text-align: center;
    margin-bottom: 30px;
}

.tabsHeading h3 {
    color: #1D458A;
}

.tabsHeading h6 span:first-child {
    color: #1D458A;
    text-align: center;
    position: relative;
    font-size: 20px;
    font-weight: 700;
}

.tabsHeading h6 span::after {
    content: "";
    position: absolute;
    background: no-repeat;
    border-bottom: 4px solid #578BC8;
    width: 70%;
    margin: auto;
    border-radius: 10px;
    bottom: -10px;
    left: 0;
    right: 0;
    height: auto;
}

.first-carousel.green-carousel .carousel {
    position: unset;
}

.service-section .first-carousel.green-carousel .carousel-control-next {
    top: 0;
}

.green-section a.carousel-control-prev,
.service-section .first-carousel.green-carousel .carousel-control-next {
    top: 0 !important;
}

.quick-contact a h6 img {
    height: 40px;
    width: 40px;
}

.yarl__container {
    background-color: transparent !important;
}

.yarl__slide_image {
    max-width: 100% !important;
    max-height: 100% !important;
    object-fit: cover !important;
    width: 100%;
    height: 100%;
    border-radius: 10px;

    @media (max-width:1200px) {
        height: auto;
    }
}

.yarl__portal_open {
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgb(0 0 0 / 75%);
}

.yarl__toolbar {
    margin-right: 5px;
    margin-top: 5px;
}

.yarl__icon {
    background-color: #08428D;
    border-radius: 50%;
    padding: 3px;
    margin: 5px;
}

@media (max-width:992px) {
    .container {
        max-width: 90%;
    }
}

.green-section.tabsHeading .first-slide-service.row {
    background: #ECF1F6;
    padding: 30px;
    margin-bottom: 30px;
}

.green-section.tabsHeading .second-slide-service.row {
    background: #ECF1F6;
    padding: 30px;
    margin-bottom: 30px;
}

.service-section.home-page {
    video {
        height: 100%;
        object-fit: contain;
    }

    .slick-slider.slick-initialized {
        height: 100%;

        .slick-list {
            height: 100%;
        }
    }
}

.home-page .video-section {
    height: 100vh;

    @media (max-width:1200px) {
        height: auto;
    }

    @media (max-width:992px) and (orientation:landscape) {
        height: 100vh;
    }

    video {
        height: 100%;
        object-fit: contain;
    }

    .slick-slider.slick-initialized {
        height: 100%;

        .slick-list {
            height: 100%;
        }
    }
}

.home-page .video-section .video-content {
    margin-bottom: 0;
    bottom: 0;

    @media (max-width:576px) {
        bottom: 6px !important;
        left: 0;
        justify-content: space-between;
    }
}

.our-offering-section ul.video-content li p {
    @media (max-width:575px) {
        font-size: 10px !important;
    }
}

.our-offering-section .home-page .video-section video {
    height: 100vh;
    object-fit: fill;
}

.our-offering-section .home-page .video-section .video-content {
    bottom: 10px;

    @media (max-width:576px) {
        overflow: overlay;
        width: 100%;
    }

    li {
        margin: 0 !important;

        @media (max-width:576px) {
            white-space: nowrap;
            margin-right: 23px !important;
            padding-bottom: 5px;
            margin-left: 5px !important;
        }
    }
}

.our-offering-section .home-page ul.video-content {

    @media (max-width:992px) and (orientation:landscape) {
        justify-content: space-between;
        bottom: 10px !important;
    }

    @media (max-width:576px) {
        bottom: 10px !important;
    }
}

.service-section.home-page .video-content {
    @media (max-width:576px) {
        left: unset;
    }
}

.home-page .video-section .progress-box {
    bottom: 1%;

    @media (max-width:576px) {
        bottom: 4%;
        display: none;
    }
}

@media (max-width:767px) {
    .progress-bar-circle small {
        font-size: 12px !important;
    }

    .progress-bar-circle svg {
        width: 50px;
        height: 50px;
    }
}

.service-content-new1 ul {
    padding-left: 2rem !important;

    li {
        list-style: disc !important;
    }
}

.service-content-new ul {
    padding-left: 2rem !important;

    li {
        list-style: disc !important;
    }
}

// .service-content-new
// .VIpgJd-ZVi9od-aZ2wEe-wOHMyf-ti6hGc{}