$primary-color: #F2F5F7;
$secondary-color: #1E2430;
$main-heading-font-size: 35px;
$heading-font-size: 24px;
$sub-heading-font-size: 20px;
$secondry-para-font-size: 16px;
$box-heading-font-size: 16px;
$primary-font-weigth: 600;
$secondary-border-radius: 20px;
$primary-font-family: 'Ubuntu', sans-serif;
$secondary-font-family: 'Kanit', sans-serif;
$box-shadow: 0px 5px 10px 0px #0000001A;
$main-padding : 50px 0;


.tab-section {
    .flex-column.nav.nav-pills {
        position: relative;

        &::after {
            position: absolute;
            width: 2px;
            height: 100%;
            top: 0;
            background: #0000006e;
            right: 0;
            content: "";
        }
    }

    padding: $main-padding ;

    .tab-heading {
        padding-bottom: 30px;

        p {
             color: #1E2430;
        }
    }

    .tab-content .nav-item .nav-link {
        font-family: $secondary-font-family;
        border-left: 4px solid transparent;
        padding: 15px;
         color: #1E2430;
    }

    .tab-content .nav-item .nav-link.active {
        background: linear-gradient(360deg, rgba(148, 140, 138, 0.25) -17.24%, rgba(255, 255, 255, 0.025) 72.41%);
        border-left: 4px solid #939598;
         color: #1E2430;
        font-family: $secondary-font-family;
        padding: 15px;
    }
}

.project-list {
    .slider-container {
        padding-bottom: 40px;

        .project-list-items {
            border: 1px solid #D4D4D5;
            border-radius: 20px;
            box-shadow: 0px 10px 16px 0px #D4D4D580;
            margin: 20px 0;

            img {
                border-top-left-radius: 20px;
                border-top-right-radius: 20px;
                height: 250px;
                object-fit: cover;
                width: 100%;
            }

            .project-list-content {
                padding: 5px 10px 20px 10px;
            }
        }

        p {
             color: #1E2430;
        }
    }
}

.why-trust-section {
    .why-trust-content {
        margin-bottom: 20px;
        p {
             color: #1E2430;
        }

        img {
            border-radius: 20px;
            height: 250px;
            width: 100%;
            object-fit: cover;
        }
    }
}

@media (max-width:768px) {
    .why-trust-section .why-trust-content {
        padding-top: 20px;
    }
}