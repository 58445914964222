$primary-color: #f2f5f7;
$secondary-color: #1E2430;
$main-heading-font-size: 35px;
$heading-font-size: 24px;
$sub-heading-font-size: 20px;
$secondry-para-font-size: 16px;
$box-heading-font-size: 16px;
$primary-font-weigth: 600;
$secondary-border-radius: 20px;
$primary-font-family: 'Ubuntu', sans-serif;
$secondary-font-family: 'Kanit', sans-serif;
$box-shadow: 0px 5px 10px 0px #0000001A;
$main-padding : 50px 0;
$padding-bottom: 50px;
$padding-bottom: 50px;

.our-offering-section {
    background-color: #f2f5f7;

    p {
        text-align: justify;
    }

    .small-text-cuverse {
        small {}
    }

    .mobile-view-1 {
        display: none;
    }

    @media (max-width:576px) {
        .mobile-view-2 {
            display: none;
        }

        .mobile-view-1 {
            display: block;
        }
    }

    @media (max-width:576px) {
        .small-text-cuverse {
            margin: 10px 0;
        }

        .what-is-floor {
            text-align: start !important;
            display: flex;
        }
    }

    .transform {
        overflow: hidden;
    }

    .my-element {
        --animate-duration: 0.5s;
    }

    @media (max-width:576px) {
        .transform-image {
            margin-top: 20px;
        }
    }

    .carousel-indicators button {
        height: 15px !important;
        width: 15px !important;
        border-radius: 50px !important;
        border: none !important;
        margin-left: 3px !important;
        background-color: #f2f5f7 !important;
    }

    .carousel-control-next,
    .carousel-control-prev {
        display: none;
    }

    .home-page {
        position: relative;
        background-color: #f2f5f7;
        padding-bottom: 0;

        @media (max-width:576px) {
            padding-bottom: 0;
        }

        @media (max-width:990px) {
            .video-section {
                overflow: hidden;
            }
        }

        .cuver-banner-heading {

            h3 {
                color: #1E2430;
            }
        }

        .video-content {

            @media (max-width:990px) {
                li {
                    margin: 0 !important;
                    width: 13% !important;
                }
            }

            li {
                @media (max-width:576px) {
                    width: auto !important;
                }
            }
        }
    }

    .progress-box {
        display: none;
    }

    ul.video-content {
        width: 100%;

        li {
            width: auto !important;
            margin-right: 51px !important;
            width: 100% !important;
            text-align: center;
        }
    }

    .transform-image {
        .carousel-inner {
            border-radius: 20px;

            img {
                width: 100%;
            }
        }
    }

    .our-offering-content {
        padding-top: 50px;
    }

    p {
        color: #1E2430;
    }

    .our-offering-floorplan {
        overflow: hidden;
        padding-bottom: $padding-bottom;

        video {
            margin-top: 20px;
            height: auto !important;
        }

        p {
            color: #1E2430;
        }

        h4 {
            margin-top: 10px !important;
            font-weight: 300;
        }
    }

    .form-section {
        background: linear-gradient(90deg, #EEEAFF 0%, #FFEAEB 100%);
        margin-bottom: $padding-bottom;
        padding: 15px 0;
    }

    .interior-360 {
        // padding: $main-padding;
    }

    .transform-content {
        p {
            color: #1E2430;
            margin-bottom: 0 !important;
        }

        @media (max-width: 992px) and (orientation: landscape) {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            transition: all 0.3s ease;


        }
    }

    .see-more-btn {
        display: none;
    }

    @media (max-width:992px) and (orientation:landscape) {
        .collapsed {
            -webkit-line-clamp: 9;
            /* Limit to 4 lines in 992px landscape mode */
        }

        .collapsed1 {
            -webkit-line-clamp: 15;
        }

        .expanded {
            -webkit-line-clamp: unset;
            /* Show full content when expanded */
        }

        .see-more-btn {
            display: block;
            color: #08428D;
            margin-bottom: 20px;
        }
    }

    img-comparison-slider.rendered {
        border-radius: 20px;
        width: 100%;

        img {
            width: 100%;
        }
    }

    .interior-360 video {
        height: auto;
        object-fit: contain;
        width: 100%;
    }

    @media(max-width:992PX) and (orientation:landscape) {
        .inventory-section .row {
            align-items: start !important;
        }
    }
}