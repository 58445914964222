$primary-font-size: 14px;
$primary-font-weigth: 600;
$primary-font-family: "Ubuntu", sans-serif;

@mixin header-items-font {
    color: #f2f5f7;
    font-weight: $primary-font-weigth ;
    font-size: $primary-font-size ;
    font-family: $primary-font-family;
    text-transform: capitalize;
}

.VIpgJd-ZVi9od-ORHb-OEVmcd {
    display: none;
}

.goog-te-gadget .goog-te-combo {
    height: 31px;
    background: transparent;
    color: #f2f5f7;
    width: 80px;
    font-size: 16px;

    option {
        color: #1E2430;
    }
}

.header-section {
    .dropdown button#dropdown-1 {
        background: transparent;
        border: none;
    }

    svg {
        width: 60px;
        height: 60px;

        &:hover {}
    }

    .menu-class1 {
        display: none;
    }

    .dropdown-menu.show {
        border-radius: 20px;
        margin-top: 20px;
        padding: 14px;

        a {
            color: #1E2430;

            &:hover {
                color: #08428D;
            }
        }
    }

    .navbar-nav {
        margin-top: 50px;
        /* align-items: center; */
        width: 100%;
        /* display: block; */
        flex-direction: column;
        align-items: start;
        height: 100%;
        align-items: center;

        select.form-select {
            width: auto;

            option {
                color: #1E2430;
            }
        }

        .form-select {
            background: transparent;
            color: #f2f5f7;
            border-color: transparent;
            font-family: $primary-font-family;
        }

        @media (max-width:576px) {
            div {
                margin-bottom: 5px;
            }
        }
    }

    a {
        text-decoration: none;
        color: #f2f5f7;
        font-family: kanit, sans-serif;
        border-bottom: 3px solid transparent;
        padding: 0;

        &:hover {
            color: #08428D;
        }

        &.active {
            // border-bottom: 3px solid #08428D;
            color: #08428D;
            font-weight: 700;
            // background: linear-gradient(360deg, rgba(220, 103, 65, 0.18) -17.24%, rgba(255, 255, 255, 0.06) 72.41%);
        }
    }

    a.navbar-brand {
        width: 20%;
    }

    nav.navbar {
        // background: #0000004d !important;
        background: #1E2430 !important;
        backdrop-filter: blur(5px);
        height: 100%;
        min-width: 20%;
        right: 0;
        left: unset;

        .container {
            align-items: start;
            display: flex;
            flex-wrap: inherit;
            justify-content: space-between;
            height: 100%;
            flex-direction: column;
            justify-content: center;
            margin-left: 24px;
        }
    }

    .menu-items {
        margin-top: 13px;
        width: 140px;
    }

    .goog-te-gadget .goog-te-combo {
        margin: 10px -5px;
    }

    .navbar-collapse {
        display: flex !important;
        flex-basis: auto;
        align-items: start;
        margin-top: 0;
        width: 100%;
    }

    @media (max-width:991px) {
        .navbar-collapse.collapse.show {
            // display: none;
        }
    }

    .burger {
        position: relative;
        width: 25px;
        height: 20px;
        cursor: pointer;
        display: block;
    }

    .burger input {
        display: none;
    }

    .burger span {
        display: block;
        position: absolute;
        height: 4px;
        width: 100%;
        background: #08428D;
        border-radius: 9px;
        opacity: 1;
        left: 0;
        transform: rotate(0deg);
        transition: .25s ease-in-out;

        @media (max-width: 575px) {
            height: 3px;
        }
    }

    .burger span:nth-of-type(1) {
        top: 0px;
        transform-origin: left center;
        width: 40%;
    }

    label.burger.burger1 span:nth-of-type(1) {
        width: 100%;
    }

    .burger span:nth-of-type(2) {
        top: 50%;
        transform: translateY(-50%);
        transform-origin: left center;
    }

    .burger span:nth-of-type(3) {
        top: 100%;
        transform-origin: left center;
        transform: translateY(-100%);
        width: 40%;
        right: 0;
        left: unset;
    }

    label.burger.burger1 span:nth-of-type(3) {
        width: 100%;
    }

    .burger input:checked~span:nth-of-type(1) {
        transform: rotate(45deg);
        top: 0px;
        left: 3px;

        @media (max-width: 992px) and (orientation: landscape) {
            top: -1px;
            left: 5px;
        }

        @media (max-width: 575px) {
            top: 0px;
            left: 3px;
        }
    }

    .burger input:checked~span:nth-of-type(2) {
        width: 0%;
        opacity: 0;
    }

    .burger input:checked~span:nth-of-type(3) {
        transform: rotate(-45deg);
        top: 18px;
        left: 3px;

        @media (max-width: 992px) {
            top: 17px;
            left: 5px;
        }

        @media (max-width: 575px) {
            top: 18px;
            left: 3px;
        }
    }
}