.service-section {
    .container {
        @media (max-width: 992px) {
            max-width: 90%;
        }

        @media (max-width:576px) {
            max-width: 100%;
        }
    }

    .row {
        min-height: 350px;
        // margin-bottom: 20px;
        padding: 10px 0 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;

        @media (min-width:1780px) {
            min-height: 400px;
        }

        @media (max-width:992px) {
            margin-bottom: 0px;
            min-height: auto;
        }

        span {
            color: #08428D !important;
            display: block;
            text-align: start;
            margin-bottom: 10px;
        }
    }

    .carousel-inner {

        // height: 400px;
    }

    ul {
        padding: 0;

        li {
            list-style: none;
        }
    }

    .main-page-heading {
        // padding-top: 20px;

        @media (max-width: 992px) and (orientation: landscape) {
            margin-bottom: 0;
            padding-top: 0;
        }

        @media (max-width: 575px) {
            margin-bottom: 0;
        }
    }

    p {
        color: #1E2430;
    }

    img {
        width: 100%;
        height: 400px;
        border-radius: 5px;

        @media (max-width:575px) {
            height: auto;
        }

        @media (min-width:1800px) {
            height: auto;
        }
    }

    .first-carousel.pre-caraousel {
        .carousel-control-prev-icon {
            background: url(../../../public/assets/images/icon/back-arrow.svg) no-repeat;

            background-size: contain;
        }

        .carousel-control-next-icon {
            background: url(../../../public/assets/images/icon/back-arrow.svg) no-repeat;

            background-size: contain;
        }

        .carousel-control-next {
            top: unset;
            left: 50%;
            height: 50px;
            right: unset;
            bottom: -70px;
            width: 50px;

            @media (max-width:1262px) {
                top: unset;
                bottom: -60px;
                left: 50%;
            }

            @media (max-width:767px) {
                left: 55%;
            }

        }

        .carousel-control-prev {
            left: 45%;
            top: unset;
            height: 50px;
            right: 0;
            bottom: -70px;
            width: 50px;

            @media (max-width:1262px) {
                top: unset;
                bottom: -60px;
                left: 42%;
            }

            @media (max-width:767px) {
                left: 35%;
            }
        }

        img {
            width: 100%;
        }
    }

    .second-carousel.pre-caraousel {
        .carousel-control-prev-icon {
            background: url(../../../public/assets/images/icon/back-arrow.svg) no-repeat;

            background-size: contain;
        }

        .carousel-control-next-icon {
            background: url(../../../public/assets/images/icon/back-arrow.svg) no-repeat;

            background-size: contain;
        }

        .carousel-control-next {
            top: unset;
            left: 50%;
            height: 50px;
            right: unset;
            bottom: -70px;
            width: 50px;

            @media (max-width:1262px) {
                top: unset;
                bottom: -60px;
                left: 50%;
            }

            @media (max-width:767px) {
                left: 55%;
            }

        }

        .carousel-control-prev {
            left: 45%;
            top: unset;
            height: 50px;
            right: 0;
            bottom: -70px;
            width: 50px;

            @media (max-width:1262px) {
                top: unset;
                bottom: -60px;
                left: 42%;
            }

            @media (max-width:767px) {
                left: 35%;
            }
        }
    }

    .first-carousel.bim-carousel {
        .carousel-inner {
            margin: 100px 0;
        }

        @media (max-width:576px) {
            .bim-content-para {
                margin-bottom: 50px !important;
            }
        }

        .carousel-control-prev-icon {
            background: url(../../../public/new_left.png) no-repeat;
            background-size: contain;
            width: 32px;

            @media (max-width:576px) {
                width: 32px;
                top: -40px !important;
            }
        }

        .carousel-control-next-icon {
            background: url(../../../public/new_right.png) no-repeat;
            background-size: contain;
            width: 32px;

            @media (max-width:576px) {
                width: 32px;
            }
        }

        .carousel-control-next {
            top: unset;
            left: 50%;
            height: 50px;
            right: unset;
            bottom: -70px;
            width: 50px;

            @media (max-width:1262px) {
                top: unset;
                bottom: -60px;
                left: 50%;
            }

            @media (max-width:767px) {
                left: 55%;
            }

        }

        .carousel-control-prev {
            left: 45%;
            top: unset;
            height: 50px;
            right: 0;
            bottom: -70px;
            width: 50px;
            right: 50px !important;

            @media (max-width:1262px) {
                top: unset;
                bottom: -60px;
                left: 42%;
            }

            @media (max-width:767px) {
                left: 35%;
            }
        }

        img {
            width: 100%;
        }
    }

    .second-carousel.bim-carousel {
        .carousel-control-prev-icon {
            bbackground: url(../../../public/new_left.png) no-repeat;
            background-size: contain;
        }

        .carousel-control-next-icon {
            background: url(../../../public/new_right.png) no-repeat;
            background-size: contain;
        }

        .carousel-control-next {
            right: 1%;
            bottom: unset;
            top: -8%;
        }

        .carousel-control-prev {
            right: 5%;
            bottom: unset;
            top: -8%;
            left: unset;
        }
    }

    .green-section {
        margin-bottom: 10px;
    }

    .green-section ul {
        padding-left: 20px;
    }

    .green-section ul li {
        list-style: disc;
    }

    .first-carousel.bim-carousel.bim-s .carousel-inner {
        margin: 0 0 20px 0;

        @media (max-width: 992px) and (orientation: landscape) {
            margin: 0 0 35px 0;
        }

        @media (max-width: 575px) {
            margin: 0;
        }
    }

    .first-carousel.green-carousel {

        .carousel-control-prev-icon {
            background: url(../../../public/new_left.png) no-repeat;
            background-size: contain;
            width: 32px;

            @media (max-width:576px) {
                width: 32px;
            }
        }

        .carousel-control-next-icon {
            background: url(../../../public/new_right.png) no-repeat;
            background-size: contain;
            width: 32px;

            @media (max-width:576px) {
                width: 32px;
            }
        }

        .carousel-control-next {
            top: 0;
            left: 50%;
            height: 50px;
            right: unset;
            bottom: -70px;
            width: 50px;

            @media (max-width:1262px) {
                top: unset;
                bottom: -60px;
                left: 50%;
            }

            @media (max-width:767px) {
                left: 55%;
            }

        }

        .carousel-control-prev {
            left: 45%;
            top: 0 !important;
            height: 50px;
            right: 0;
            bottom: -70px;
            width: 50px;
            right: 50px !important;

            @media (max-width:576px) {
                right: 40px !important;
            }

            @media (max-width:1262px) {
                top: unset;
                bottom: -60px;
                left: 42%;
            }

            @media (max-width:767px) {
                left: 35%;
            }
        }

        img {
            width: 100%;
        }
    }

    .carousel-indicators {
        display: none;
    }

    .pre-caraousel,
    .green-carousel,
    .bim-carousel {}

    [data-bs-theme=dark] .carousel .carousel-control-next-icon,
    [data-bs-theme=dark] .carousel .carousel-control-prev-icon,
    [data-bs-theme=dark].carousel .carousel-control-next-icon,
    [data-bs-theme=dark].carousel .carousel-control-prev-icon {
        filter: unset;
    }

    .carousel-control-next,
    .carousel-control-prev {
        opacity: 1;
    }

    .carousel-control-prev-icon,
    .carousel-control-next-icon {
        width: 40px;
        height: 40px;
    }

    @media (min-width: 1800px) {
        p {
            font-size: 20px;
        }
    }

    .render-page {
        .row {
            margin-bottom: 0 !important;
            // row-gap: 25px;
            margin-top: 10px;
        }

        .slick-dots {
            bottom: 10px;

            @media (max-width: 992px) and (orientation: landscape) {
                bottom: 15px;
            }

            @media (max-width: 575px) {
                bottom: 55px;
            }
        }

        .slick-prev,
        .slick-next {
            display: none !important;
        }

        .slick-prev:before,
        .slick-next:before {
            color: #08428D;
            font-size: 24px;
        }

        .renderImagesBox {
            position: relative;


            a img {
                height: 100%;
                border-radius: 10px;
                box-shadow: none;

                @media (max-width: 575px) {
                    margin-top: 0px;
                    padding-top: 0px;
                }
            }

            .play-btn {
                position: absolute;
                top: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 100%;
            }
        }

        .renders-content button {
            background: #08428D;
            color: #f2f5f7;
            border-radius: 5px;
            padding: 2px 10px;
            border: 1px solid transparent;

            @media (max-width: 575px) {
                display: none;
            }

            &:hover {
                background-color: transparent;
                color: #08428D;
                border-color: #08428D;
            }
        }

        ul {
            padding-left: 25px;
            margin-top: 10px;
            margin-bottom: 0;

            @media (max-width: 575px) {
                margin-bottom: 8px;
            }

            li {
                list-style: disc;
                font-size: 14px;
                font-weight: 500;
            }


        }

        .renderImagesBox:hover .preview-images {
            opacity: 1;

            small {
                color: #f2f5f7;
                font-size: 16px;
                font-weight: 500;
            }
        }

        .slick-dots li button:before {
            font-size: 18px;
            color: #000000;
        }

        .youtube-videos {

            .slick-slider.slick-initialized,
            .slick-list,
            .slick-track {
                height: 100%;

            }

            .slick-slide div:first-child {
                height: 100%;
                position: relative;
            }
        }

        .preview-images {
            position: absolute;
            content: "";
            top: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 10px;
            transition: 0.4s;
            color: #f2f5f7;
            right: 10px;

            @media (max-width: 992px) and (orientation: landscape) {
                top: 10px;
            }

            @media (max-width: 575px) {
                top: 10px;
            }
        }

        .preview-images.bottom-menu {
            bottom: 20px;
            top: unset;

            @media (max-width:767px) {
                left: unset;
                bottom: unset;
                top: 20px;
                right: 20px;
            }
        }

        .preview-images.top-menu {
            left: 20px;
            bottom: 20px;
            top: unset;
            justify-content: start;
            right: unset;

            @media (max-width:767px) {
                left: unset;
                bottom: unset;
                top: 20px;
                right: 20px;
            }
        }

        iframe {
            border-radius: 10px;
        }

        .cinematic-videos-images {
            cursor: pointer;
            width: 100%;
            margin: auto;
            position: relative;

            @media (max-width: 575px) {
                margin-bottom: 5px;
            }
        }

        button.preview-btn {
            background: #00000060;
            color: #f2f5f7;
            padding: 5px;
            margin: 5px 5px;
            border-radius: 5px;
            border: 1px solid transparent;
            // line-height: 1;
            height: 30px;
            font-weight: 600;
            display: flex;
            align-items: center;
            justify-content: center;
            text-decoration: none;
        }

        button.preview-btn:hover {
            background-color: transparent;
            border-color: #08428D;
            color: #08428D;
        }

        a {
            text-decoration: none;
        }
    }
}


.cinematic-videos-images img {
    @media (max-width: 575px) {
        // margin: 2px 5px 20px;
    }
}

.pre-design-content .renders .interior-img {
    @media (max-width: 575px) {
        margin: 15px 0 15px;
    }
}

.render-page .interior-renders .render-360 ul {
    @media (max-width: 575px) {
        margin-bottom: 15px;
    }
}

.nav-tabs .nav-link {
    background-color: #00000015;
    color: #08428D;
    padding: 4px 8px;
    border-radius: 8px;
    margin: 4px;
    font-weight: 400;
}


.render-page-tabs {
    .nav.nav-tabs {
        margin-top: 0px;
        position: relative;
        top: -42px;
        left: 71px;
        width: calc(100% - 71px);
        justify-content: center;
        border: none;

        @media (max-width: 575px) {
            // margin-top: -20px;
            flex-wrap: nowrap;
            justify-content: space-between;
            overflow: overlay;
            left: 50px;

            li {
                button {
                    white-space: nowrap;
                }
            }
        }
        li {
            background-color: #ECF1F6 ;
            button {
                border: none;
            }
        }
    }

    @media (max-width:992px) {
        h1 {
            font-size: 20px !important;
            top: 20px;
        }
    }

    .nav-tabs .nav-item.show .nav-link,
    .nav-tabs .nav-link.active {
        background-color: #08428D;
        color: #f2f5f7;
        padding: 4px 9px;
        border-radius: 8px;
    }
}


.fade.video-modal.modal.show .modal-dialog {
    /* max-height: 100%; */
    max-width: 100%;
    width: 100%;
    // height: 92vh;

    .modal-content {
        height: 100%;
    }
}

.exterior-renders .renderImagesBox .less-space {
    @media (max-width: 575px) {
        // margin: 15px 0 25px;
    }
}

.interior-renders .renders-content ul {
    @media (max-width: 575px) {
        margin-bottom: 15px;
    }
}

.interior-tab .interior-cinematic ul {
    @media (max-width: 575px) {
        margin-bottom: 15px;
    }
}

.exterior-renders.youtube-videos .ytp-chrome-bottom {
    display: none;
}

.exterior-renders.youtube-videos .ytp-chrome-bottom,
.ytp-chrome-top.ytp-show-cards-title,
a.ytp-watermark.yt-uix-sessionlink {
    display: none;
}