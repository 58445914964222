$primary-color: #F2F5F7;
$secondary-color: #1E2430;
$main-heading-font-size: 35px;
$heading-font-size: 24px;
$sub-heading-font-size: 20px;
$secondry-para-font-size: 16px;
$box-heading-font-size: 16px;
$primary-font-weigth: 600;
$secondary-border-radius: 20px;
$primary-font-family: 'Ubuntu', sans-serif;
$secondary-font-family: 'Kanit', sans-serif;
$box-shadow: 0px 5px 10px 0px #0000001A;
$main-padding : 50px 0;

.about-content {
    padding: $main-padding;

    h3 {
        margin-bottom: 30px;
    }

    .carousel-inner {
        .carousel-item img.img-fluid {
            border-radius: 20px;
        }
    }

    .founder-message {
        padding: $main-padding;
    }

    .cubedots-life {
        padding-bottom: 50px;
    }

    .exhibition-section {
        padding: $main-padding;

        img {
            height: 100%;
        }
    }

    p {
        color: #1E2430;
        text-align: justify;
    }

    .experience-image {
        position: relative;
        border-radius: 20px;
        text-align: center;
        margin-bottom: 10px;


        p {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            text-align: center;
            background-color: #F2F5F7;
            border: 1px solid #D4D4D5;
            border-bottom-left-radius: 20px;
            border-bottom-right-radius: 20px;
            padding: 5px;
        }

        img {
            border-radius: 20px;
            height: 250px;
            width: 100%;
        }
    }

    .our-team {
        .our-team-items {
            img {
                height: 200px;
                width: 100%;
                object-fit: cover;
                border-top-left-radius: 20px;
                border-top-right-radius: 20px;
            }

            .our-team-content {
                padding: 5px 15px;
            }
        }

        .card {
            border-radius: 20px;
        }
    }
}