$primary-font-size: 22px;
$primary-font-weigth: 600;
$primary-font-family: "Ubuntu", sans-serif;

@mixin header-items-font {
    color: #f2f5f7;
    font-weight: $primary-font-weigth ;
    font-size: $primary-font-size ;
    font-family: $primary-font-family;
    text-transform: capitalize;
}

.footerSection {
    font-family: $primary-font-family;
    background-color: #313341;
    padding: 20px 0;

    object {
        width: 100%;
    }

    .toggle-info {
        background: #f2f5f7;
        color: #1E2430;
        border-radius: 30px;
        padding: 5px 20px;
        min-width: 58%;
        z-index: 3;

        @media (max-width:576px) {
            min-width: 71%;
        }

        &:before {
            content: "";
            position: absolute;
            bottom: 0;
            right: 0;
            background: #D1FFEF;
            width: 66px;
            height: 56px;
            border-radius: 7% 59% 0 100%;
            top: 0;
            right: 0;
            rotate: 358deg;
        }

        p {
            color: #1E2430;
        }

        &::after {
            position: absolute;
            content: "";
            height: 20px;
            width: 20px;
            background: white;
            bottom: -2px;
            /* left: 0; */
            right: 36%;
            transform: rotate(45deg);
            z-index: -1;
        }
    }

    .footer-items {
        color: #f2f5f7;
        margin-top: 18px;
        justify-content: center;

        @media (max-width:992px) and (orientation:landscape) {
            margin-top: 20px;
        }

        @media (max-width:767px) and (orientation:landscape) {
            margin-top: 5px;
            justify-content: start;
        }

        @media (max-width:576px) {
            margin-top: 5px;
            justify-content: start;
        }

        @media (min-width:1500px) {
            justify-content: start;
        }

        a {
            text-decoration: none;
        }

        p:hover {
            color: #08428D;
        }
    }

    h3 {
        color: #f2f5f7;
        @include header-items-font;
        margin-bottom: 20px;
        margin-top: 5px;

        @media (max-width:767px) and (orientation:landscape) {
            margin-bottom: 5px;
            margin-top: 20px;
        }

        @media (max-width:576px) {
            margin-bottom: 5px;
            margin-top: 20px;
        }
    }

    p {
        font-weight: 300;
        text-align: justify;
    }

    .footer-contact-detail {
        display: flex;
        align-items: center;
        margin: 5px;

        p {
            margin-left: 20px;
        }
    }

    .footer-icons {
        img {
            margin: 5px;
        }
    }

    .form-section {
        position: relative;
        border-radius: 20px;

        .form-control {
            border-radius: 20px;
            height: 30px;
            font-size: 12px;
        }

        button {
            position: absolute;
            top: 0;
            right: 0;
            border-radius: 20px;
            background-color: #08428D;
            padding: 6px 26px;
            border-color: transparent;
            height: 100%;
            line-height: 1;
        }
    }

    @media (max-width:768px) {
        object {
            width: 100%;
        }
    }


}

.terms-condition:hover {
    color: #08428D;
}

@media (max-width:576px) {
    .copyright_text {
        text-align: start !important;
    }
}